import { EnumToArray } from '../../../utils/enumToArray';

enum Size {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xlg',
}

enum Mode {
  Increment = 'increment',
  Decrement = 'decrement',
  None = 'none',
  All = 'all'
}

export const PTZCounterConfigList = {
  Size: EnumToArray([Size]),
  Mode: EnumToArray([Mode]),
};

export const PTZCounterConfig = {
  Size,
  Mode,
};
